<template>
    <div class="inner-section a11-wrapper">
      <card>
        <template v-slot:searchHeaderTitle>
            <h4 class="card-title">{{ $t('globalTrans.application') }} {{ $t('globalTrans.details') }}</h4>
        </template>

        <b-container fluid>
            <b-overlay :show="loading">
                <div class="text-right">
                    <b-button @click="print" variant="primary" class="btn btn-sm mb-2">
                        <i class="ri-printer-line"></i> {{ $t('globalTrans.print') }}
                    </b-button>
                </div>
                <div id="printMe">
                <div class="d-flex justify-content-between mb-4">
                    <p>{{ $t('teaGardenService.application_id') }}: {{ appDetails.app_id }}</p>
                    <p>{{ $t('globalTrans.date') }}: {{ appDetails.application_date | dateFormat }}</p>
                    </div>
                    <div class="apps-subject-wrapper mb-4">
                    <p class="mb-0">{{ $t('teaGardenBtriService.director_label') }}</p>
                    <p class="mb-0">{{ $t('teaGardenBtriService.btb_label') }}</p>
                    <p class="mb-0">{{ $t('teaGardenBtriService.upazila_label') }}</p>
                    <p class="mb-0">{{ $t('globalTrans.district') }}- {{ $t('teaGardenBtriService.district_name') }}</p>
                    </div>
                    <p class="mb-4">{{ $t('teaGardenBtriService.subject') }}:
                        <span v-if="currentLocale === 'en'">{{ $t('teaGardenBtriService.subject_end') }} </span>
                        <span class="dynamic-line-wrapper">
                            {{ getServiceName(appDetails.service_id) }}
                        </span>
                        <span v-if="currentLocale === 'bn'"> {{ $t('teaGardenBtriService.subject_end') }}</span>
                    </p>
                    <div class="cover-letter-wrapper" style="text-align: justify">
                        <p class="mb-2">{{ $t('teaGardenBtriService.dear') }}</p>
                        <p style="line-height: 2rem;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('teaGardenBtriService.greeting_heading') }}
                            <span class="dynamic-line-wrapper">{{ getGardenName(appDetails.garden_id) }}</span> {{ $t('teaGardenBtriService.tea_plant_supply_greeting_2nd') }}
                        </p>
                    </div>
                <br>
                <table class="table b-table tg table-striped table-hover table-bordered">
                    <b-thead>
                        <tr>
                            <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                            <b-th class="text-center">{{ $t('teaGardenBtriService.planting_material') }}</b-th>
                            <b-th class="text-center">{{ $t('teaGardenBtriService.clone_variety') }}</b-th>
                            <b-th class="text-center">{{ $t('globalTrans.price') }}</b-th>
                            <b-th class="text-center">{{ $t('globalTrans.quantity') }}</b-th>
                            <b-th class="text-center">{{ $t('globalTrans.tot_price') }}</b-th>

                            <b-th class="text-center">{{ $t('teaGardenBtriService.approve_qty') }}</b-th>
                            <b-th class="text-center">{{ $t('teaGardenBtriService.approve_tot_price') }}</b-th>
                        </tr>
                    </b-thead>
                    <b-tbody>
                        <template v-if="appDetails.details && appDetails.details.length">
                            <b-tr v-for="(item, index) in appDetails.details" :key="index">
                                <b-td class="text-center">{{ $n(index+1) }}</b-td>
                                <b-td class="text-center">{{ getMatName(item.planting_material_id) }}</b-td>
                                <b-td class="text-center">{{ getCloneName(item.clone_name_id) }}</b-td>
                                <b-td class="text-center">{{ $n(item.price) }}</b-td>
                                <b-td class="text-center"> {{ $n(totQuantity(item.qty)) }}</b-td>
                                <b-td class="text-center">{{ $n(item.total_price) }}</b-td>

                                <b-td class="text-center"> {{ $n(item.approved_qnty) }}</b-td>
                                <b-td class="text-center">{{ $n(item.approved_tot_price) }}</b-td>
                            </b-tr>
                            <b-tr>
                            <b-td class="text-right" colspan="4">{{ $t('globalTrans.grand_total') }}</b-td>
                            <b-td class="text-center" >{{ $n(appDetails.total_qty) }}</b-td>
                            <b-td class="text-center">{{ $n(appDetails.total_price) }}</b-td>
                            <b-td class="text-center">{{ appDetails.tot_approved_qty > 0 ? $n(appDetails.tot_approved_qty) : ' '}}</b-td>
                            <b-td class="text-center">{{ appDetails.tot_approved_price > 0 ? $n(appDetails.tot_approved_price) : ' ' }}</b-td>
                            </b-tr>
                        </template>
                        <template v-else>
                        <b-tr>
                            <b-td colspan="7" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                        </b-tr>
                        </template>
                    </b-tbody>
                </table>
                <div class="d-flex justify-content-between mt-5 mx-3">
                    <p>{{ $t('teaGardenBtriService.thanks') }}</p>
                    <p>{{ $t('teaGardenBtriService.your_faith') }} <br> <br>
                        ---------------
                    </p>
                </div>
        </div>
                <b-button @click="back" class="ml-3"><i class="ri-arrow-go-back-fill"></i>  {{ $t('teaGardenConfig.back') }}</b-button>
            </b-overlay>
        </b-container>
      </card>
    </div>
  </template>
  <script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { btriServiceEdit } from '../../../api/routes'
import Vue from 'vue'
import VueHtmlToPaper from 'vue-html-to-paper'
Vue.use(VueHtmlToPaper)

export default {
data () {
    return {
        loading: false,
        appDetails: {},
        pageStyle: {
            styles: [
                'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
                `${teaGardenServiceBaseUrl}/custom.css`
            ]
        }
    }
},
created () {
    this.getFormData()
},
computed: {
    currentLocale () {
        return this.$i18n.locale
    },
    masterCloneNameList: function () {
        return this.$store.state.TeaGardenService.commonObj.masterCloneNameList
    }
},
methods: {
    print () {
        this.$htmlToPaper('printMe', this.pageStyle)
    },
    back () {
        this.$router.go(-1)
    },
    totQuantity (qnty) {
        // this.beneficiary.total_qty += parseInt(qnty)
        return qnty
    },
    async getFormData () {
        this.loading = true
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }
        result = await RestApi.getData(teaGardenServiceBaseUrl, `${btriServiceEdit}/${this.$route.query.id}`)
        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)
        if (result.success) {
            this.appDetails = result.data
        } else {
            this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: 'Clone Price not Found!!!',
                color: '#D6E09B'
            })
        }
        this.loading = false
    },
    getMatName (id) {
        const data = this.$store.state.TeaGardenService.commonObj.plantingMaterialList.find(item => item.value === id)
        if (data) {
            return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
        }
    },
    getCloneName (id) {
        const data = this.masterCloneNameList.find(item => item.value === id)
        if (data) {
            return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
        }
    },
    getServiceName (id) {
        const data = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === id)
        if (data) {
            return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
        }
    },
    getGardenName (id) {
        const data = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === id)
        if (data) {
            return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
        }
    }
}
}
</script>
